import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { PageLayout } from "../components/page-layout";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm } from "react-hook-form";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const AdminPage = () => {
    const baseApiURL = process.env.REACT_APP_API_ENDPOINT;
    const { getAccessTokenSilently } = useAuth0();
    const [status, setStatus] = useState("");
    const [saving, setSaving] = useState(false);

    const getSettings = async () => {
        const accessToken = await getAccessTokenSilently();
        const kaiyaSettings = await axios.get(`${baseApiURL}/settings`, {
            headers: {
                'content-type': 'application/json',
                Authorization: `${accessToken}`
            }
        });

        return kaiyaSettings.data;
    };

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
        defaultValues: async () => getSettings()
    });

    const onSubmit = async (data) => {
        setSaving(true);
        console.log(data);

        const accessToken = await getAccessTokenSilently();
        await axios.post(`${baseApiURL}/settings`, data, {
            headers: {
                'content-type': 'application/json',
                Authorization: `${accessToken}`
            }
        });

        setSaving(false);
    };



    useEffect(() => {
        const getStatus = async () => {
            const accessToken = await getAccessTokenSilently();
            const tikaServerStatus = await axios.get(`${baseApiURL}/tikastatus`, {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `${accessToken}`
                }
            });

            setStatus(tikaServerStatus.data);
        };

        getStatus();
    }, [getAccessTokenSilently, baseApiURL]);

    return (
        <PageLayout title='Admin'>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">

                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Kaiya configuration</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">(Click on a value to edit it)</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Model</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <input {...register('model', { required: true })}/>
                                    {errors.model && <span className=" ml-2 text-sm text-red-600">A model name is required</span>}
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Frequency penalty</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <input {...register('frequency_penalty', { required: true })}/>
                                    {errors.frequency_penalty && <span className=" ml-2 text-sm text-red-600">Freq. Penalty is required</span>}
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Max tokens</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <input {...register('max_tokens', { required: true })}/>
                                    {errors.max_tokens && <span className=" ml-2 text-sm text-red-600">Max tokens is required</span>}
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Presence penalty</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <input {...register('presence_penalty', { required: true })}/>
                                    {errors.presence_penalty && <span className=" ml-2 text-sm text-red-600">Presence penalty is required</span>}
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Temperature</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <input {...register('temperature', { required: true })}/>
                                    {errors.temperature && <span className=" ml-2 text-sm text-red-600">Temperature is required</span>}
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Top P</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <input {...register('top_p', { required: true })}/>
                                    {errors.top_p && <span className=" ml-2 text-sm text-red-600">top_p is required</span>}
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500">Prompt</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <textarea cols={60} {...register('prompt', { required: true })} placeholder='(Ensure your prompt includes ${this.context} and ${this.query} placeholders)'/>
                                    {errors.prompt && <span className=" ml-2 text-sm text-red-600">A prompt is required</span>}
                                </dd>
                            </div>
                        </dl>
                </div>

            </div>
                <button className='mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' type='submit'>{saving ? 'Saving...' : 'Save configuration'}</button>
                <button className='mt-6 ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={() => reset()}>Reset to default values</button>
            </form>

            <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-8">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Tika Server</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">ServerID {status.server_id}</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Status</dt>
                            <dd className={classNames(status.status === 'OPERATING' ? 'text-green-600' : 'text-orange-600', 'mt-1 text-sm sm:col-span-2 sm:mt-0')}>{status.status}</dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Time since last parse started</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{status.millis_since_last_parse_started}</dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Files Processed</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{status.files_processed}</dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">Restarts</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{status.num_restarts}</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </PageLayout>
    );
}
