import React from "react";
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/24/outline'
import { useAuth0 } from "@auth0/auth0-react";
import {LoginButton} from './buttons/login-button';
import {LogoutButton} from './buttons/logout-button';
import { NavLink } from "react-router-dom";

let navigation = [
    { name: 'Live', href: '/', current: true},
    { name: 'Playground', href: '/playground', current: true },
    { name: 'History', href: '/history', current: true },
    { name: 'Admin', href: '/admin', current: true }
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const NavBar = () => {
    const { isAuthenticated, user } = useAuth0();

    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                {/*<div className="flex-shrink-0">*/}
                                {/*    <img className='h-8 w-8' src={logo} alt='Robora company logo'/>*/}
                                {/*</div>*/}
                                <div className="hidden md:block">
                                    <div className="ml-10 flex items-baseline space-x-4">
                                        {navigation.map((item) => (
                                            <NavLink to={item.href}
                                                     className={({ isActive, isPending }) =>
                                                         classNames(
                                                             isActive
                                                                 ? 'bg-gray-900 text-white'
                                                                 : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                             'rounded-md px-3 py-2 text-sm font-medium'
                                                         )
                                                     }>
                                                {item.name}
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-4 flex items-center md:ml-6">
                                    <span className='text-gray-400 select-none'>{user.name}</span>
                                    {/* Profile dropdown */}
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                <button
                                                    type="button"
                                                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                                >
                                                    <span className="sr-only">Open user menu</span>
                                                    <UserIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {!isAuthenticated && (
                                                    <Menu.Item key='notAuthenticated'>
                                                        {({ active }) => (
                                                            <LoginButton
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100' : '',
                                                                        'block px-4 py-2 text-sm text-gray-700 w-full'
                                                                    )}
                                                            />
                                                        )}
                                                    </Menu.Item>
                                                )}
                                                {isAuthenticated && (
                                                    <Menu.Item key='notAuthenticated'>
                                                        {({ active }) => (
                                                            <LogoutButton
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700 w-full'
                                                                )}
                                                            />
                                                        )}
                                                    </Menu.Item>
                                                )}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Disclosure>
    );
};
