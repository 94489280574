import React, {useState, useRef} from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";

export const PlaygroundPage = () => {
    const ref = useRef();
    const baseApiURL = process.env.REACT_APP_API_ENDPOINT;
    const { getAccessTokenSilently } = useAuth0();
    const [file, setFile] = useState();
    const [fileText, setFileText] = useState();

    function handleFileChange(event) {
        setFile(event.target.files[0]);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        // Step 1 - Get a presigned URL from our API to upload our file to:
        const accessToken = await getAccessTokenSilently();
        const uploadUrl = await axios.post(`${baseApiURL}/getUploadUrl`, {
            filename: file.name
        }, {
            headers: {
                Authorization: `${accessToken}`
            }
        });

        console.log(uploadUrl.data);

        const url = uploadUrl.data;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        await axios.put(url, formData, config);
        const tikeOutput = await axios.post(`${baseApiURL}/extract`, {
            filename: file.name
        }, {
            headers: {
                Authorization: `${accessToken}`
            }
        });

        console.log(tikeOutput.data);
        setFileText(tikeOutput.data);
        ref.current.value = "";
    }

    return (
        <PageLayout title='Playground'>
            <div className="py-10">
                <main>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                type='file'
                                ref={ref}
                                onChange={handleFileChange}
                                className='text-slate-600 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-700'
                            />
                        </div>

                        {file && (
                            <button className='mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' type='submit'>Upload & Process</button>
                        )}
                    </form>
                    {fileText && (
                        <>
                            <h1>File contents:</h1>
                            <pre>{fileText}</pre>
                        </>
                    )}
                </main>
            </div>
        </PageLayout>
    );
}
