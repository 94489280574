import React, {useEffect, useState, useMemo} from 'react';
import axios from 'axios';
import { PageLayout } from "../components/page-layout";
import { useAuth0 } from "@auth0/auth0-react";
import { useTable } from 'react-table'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const HistoryPage = () => {
    const baseApiURL = process.env.REACT_APP_API_ENDPOINT;
    const { getAccessTokenSilently } = useAuth0();
    const [history, setHistory] = useState([]);

    useEffect(() => {
        const getHistory = async () => {
            const accessToken = await getAccessTokenSilently();
            const historyData = await axios.get(`${baseApiURL}/history`, {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `${accessToken}`
                }
            });

            console.log(historyData.data[0]);
            setHistory(historyData.data);
        };

        getHistory();
    }, [getAccessTokenSilently, baseApiURL]);

    const data = React.useMemo(
        () => history,
        [history]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'User',
                accessor: 'pk', // accessor is the "key" in the data
            },
            {
                Header: 'TS',
                accessor: 'sk',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: ({ value }) => JSON.stringify(value)
            },
        ],[]
    );

    const tableInstance = useTable({ columns, data });
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    return (
        <PageLayout title='History'>
            {history.length > 0 && (
                <table  {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )}
        </PageLayout>
    );
}
