import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { PageLayout } from "../components/page-layout";
import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

export const LivePage = () => {
    const [socketUrl] = useState('wss://md1jrk0ag9.execute-api.eu-west-2.amazonaws.com/dev');
    const [messageHistory, setMessageHistory] = useState([]);
    const { lastMessage, readyState } = useWebSocket(socketUrl, {
        shouldReconnect: (closeEvent) => true,
        retryOnError: true
    });

    useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory((prev) => prev.concat(lastMessage));
        }
    }, [lastMessage, setMessageHistory]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Connected to platform',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <PageLayout title='Live'>
            <span className={classNames(
                connectionStatus === 'Connected to platform' ? 'bg-green-100 text-green-600' : 'bg-yellow-50 text-yellow-600',
                'rounded-full px-2.5 py-0.5 text-sm font-medium'
            )} >{connectionStatus}</span>

            <div className="flow-root">
                <ul role="list" className="space-y-3 mt-4">
                    {messageHistory.reverse().map((message, idx) => (
                        <li key={idx} className="overflow-hidden rounded-md bg-gray-300 px-6 py-4 shadow">
                            <ul role='list' className='space-y-3 mt-4'>
                                {JSON.parse(message.data).map((action, idx2) => (
                                    <li key={idx2}  className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
                                        <div className="relative">
                                            <div className="relative flex space-x-3">
                                                <div className="flex min-w-0 flex-1 justify-between space-x-4">
                                                    <div>
                                                        <p className="text-sm text-gray-500">
                                                            {action.type === 'User' && (
                                                                <>
                                                                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">User:</p>
                                                                    <p className="text-sm font-semibold leading-6 text-gray-900">{action.payload}</p>
                                                                </>
                                                            )}

                                                            {action.type === 'Query' && (
                                                                <>
                                                                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">Query:</p>
                                                                    <p className="text-sm font-semibold leading-6 text-gray-900">{action.payload}</p>
                                                                </>
                                                            )}

                                                            {action.type === 'MatchedDocs' && (
                                                                <>
                                                                    <div className="w-full">
                                                                        <div className="mx-auto w-full max-w-md rounded-2xl bg-white">
                                                                            <Disclosure>
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                                                                            <span>Matching docs</span>
                                                                                            <ChevronRightIcon
                                                                                                className={`${
                                                                                                    open ? 'h-5 w-5 rotate-90 transform' : 'h-5 w-5'
                                                                                                } h-5 w-5 text-purple-500`}
                                                                                            />
                                                                                        </Disclosure.Button>
                                                                                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                                                                                            <pre>{JSON.stringify(action.payload, null, 2)}</pre>
                                                                                        </Disclosure.Panel>
                                                                                    </>
                                                                                )}
                                                                            </Disclosure>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {action.type === 'DocContent' && (
                                                                <>
                                                                    <div className="w-full">
                                                                        <div className="mx-auto w-full max-w-md rounded-2xl bg-white">
                                                                            <Disclosure>
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                                                                            <span>Doc chunk content</span>
                                                                                            <ChevronRightIcon
                                                                                                className={`${
                                                                                                    open ? 'h-5 w-5 rotate-90 transform' : 'h-5 w-5'
                                                                                                } h-5 w-5 text-purple-500`}
                                                                                            />
                                                                                        </Disclosure.Button>
                                                                                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                                                                                            <pre>{JSON.stringify(action.payload, null, 2)}</pre>
                                                                                        </Disclosure.Panel>
                                                                                    </>
                                                                                )}
                                                                            </Disclosure>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {action.type === 'Summary' && (
                                                                <>
                                                                    <div className="w-full">
                                                                        <div className="mx-auto w-full max-w-md rounded-2xl bg-white">
                                                                            <Disclosure>
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                                                                            <span>Summary</span>
                                                                                            <ChevronRightIcon
                                                                                                className={`${
                                                                                                    open ? 'h-5 w-5 rotate-90 transform' : 'h-5 w-5'
                                                                                                } h-5 w-5 text-purple-500`}
                                                                                            />
                                                                                        </Disclosure.Button>
                                                                                        <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                                                                                            <pre>{JSON.stringify(action.payload, null, 2)}</pre>
                                                                                        </Disclosure.Panel>
                                                                                    </>
                                                                                )}
                                                                            </Disclosure>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {action.type === 'error' && (
                                                                <>
                                                                    <div className="w-full">
                                                                        <div className="mx-auto w-full max-w-md rounded-2xl bg-white">
                                                                            <Disclosure>
                                                                                {({ open }) => (
                                                                                    <>
                                                                                        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-red-300 px-4 py-2 text-left text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                                                                                            <span>Error</span>
                                                                                            <ChevronRightIcon
                                                                                                className={`${
                                                                                                    open ? 'h-5 w-5 rotate-90 transform' : ''
                                                                                                } h-5 w-5 text-red-500`}
                                                                                            />
                                                                                        </Disclosure.Button>
                                                                                        <Disclosure.Panel className="pt-4 text-sm text-gray-500">
                                                                                            <pre>{JSON.stringify(action.payload, null, 2)}</pre>
                                                                                        </Disclosure.Panel>
                                                                                    </>
                                                                                )}
                                                                            </Disclosure>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                                        {action.date}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>


            {/*<div className="mt-4 overflow-hidden bg-white shadow sm:rounded-lg">*/}
            {/*    <ul role="list" className="divide-y divide-gray-100">*/}
            {/*        {messageHistory.reverse().map((message, idx) => (*/}
            {/*            <li key={idx} className="flex justify-between gap-x-6 py-5">*/}
            {/*                <div className="flex gap-x-4">*/}
            {/*                    <div className="min-w-0 flex-auto">*/}

            {/*                        {JSON.parse(message.data).map((action, idx2) => (*/}
            {/*                            <>*/}
            {/*                                {action.type === 'Query' && (*/}
            {/*                                    <>*/}
            {/*                                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{action.type}</p>*/}
            {/*                                        <p className="text-sm font-semibold leading-6 text-gray-900">{action.payload}</p>*/}
            {/*                                    </>*/}
            {/*                                )}*/}

            {/*                                {action.type === 'MatchedDocs' && (*/}
            {/*                                    <>*/}
            {/*                                        <Disclosure>*/}
            {/*                                            {({ open }) => (*/}
            {/*                                                <>*/}
            {/*                                                    <Disclosure.Button className='bg-pink-400'>*/}
            {/*                                                        Matched Docs <ChevronRightIcon className={open ? 'h-5 w-5 rotate-90 transform' : 'h-5 w-5 '} />*/}
            {/*                                                    </Disclosure.Button>*/}
            {/*                                                    <Disclosure.Panel>*/}
            {/*                                                        <pre>{JSON.stringify(action.payload, null, 2)}</pre>*/}
            {/*                                                    </Disclosure.Panel>*/}
            {/*                                                </>*/}
            {/*                                            )}*/}
            {/*                                        </Disclosure>*/}
            {/*                                    </>*/}
            {/*                                )}*/}
            {/*                            </>*/}
            {/*                        ))}*/}

            {/*                        /!*{JSON.parse(message.data).type === 'Query' && (*!/*/}
            {/*                        /!*    <>*!/*/}
            {/*                        /!*        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{JSON.parse(message.data).type}</p>*!/*/}
            {/*                        /!*        <p className="text-sm font-semibold leading-6 text-gray-900">{JSON.stringify(JSON.parse(message.data).message)}</p>*!/*/}
            {/*                        /!*    </>*!/*/}
            {/*                        /!*)}*!/*/}

            {/*                        /!*{JSON.parse(message.data).type === 'MatchedDocs' && (*!/*/}
            {/*                        /!*    <>*!/*/}
            {/*                        /!*        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{JSON.parse(message.data).type}</p>*!/*/}
            {/*                        /!*        <pre>{JSON.stringify(JSON.parse(message.data).message, null, 2)}</pre>*!/*/}
            {/*                        /!*    </>*!/*/}
            {/*                        /!*)}*!/*/}

            {/*                    </div>*/}
            {/*                </div>*/}

            {/*            </li>*/}
            {/*        ))}*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </PageLayout>
    );
}
