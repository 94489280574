import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import { sendToVercelAnalytics } from './vitals';

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Auth0ProviderWithNavigate>
              <App />
          </Auth0ProviderWithNavigate>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(sendToVercelAnalytics);
