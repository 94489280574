import { Route, Routes } from 'react-router-dom';
import { PlaygroundPage } from './pages/playground-page';
import { AdminPage } from './pages/admin-page';
import { HistoryPage } from './pages/history-page';
import { LivePage } from './pages/live-page';
import { NotFoundPage } from './pages/not-found-page';
import { useAuth0 } from "@auth0/auth0-react";
import {PageLoader} from './components/fullpage-loading';
import { AuthenticationGuard } from "./components/authentication-guard";

function App() {
    const { isLoading } = useAuth0();
    if (isLoading) {
        return (
            <div>
                <PageLoader />
            </div>
        );
    }

  return (
    <Routes>
        <Route
            path='/'
            element={<AuthenticationGuard component={LivePage} />}
        />
        <Route
            path='/history'
            element={<AuthenticationGuard component={HistoryPage} />}
        />
        <Route
            path='/playground'
            element={<AuthenticationGuard component={PlaygroundPage} />}
        />
        <Route
            path='/admin'
            element={<AuthenticationGuard component={AdminPage} />}
        />
        <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
