import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = ({className}) => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        console.log('here');
        await loginWithRedirect({
            appState: {
                returnTo: "/",
            },
        });
    };

    return (
        <button onClick={handleLogin} className={className}>
            Log In
        </button>
    );
};
